import { ProductBadgesList } from '@/core/ui/components/ProductBadgesList/ProductBadgesList';
import { ProductImage } from '@/core/ui/components/ProductImage/ProductImage';
import { ProductPageLink } from '@/core/ui/components/ProductPageLink/ProductPageLink';
import { cn } from '@/core/ui/utils';
import { useProductTileContext } from '@/modules/product/hooks/useProductTileContext';
import { Product } from '@/modules/product/queries/types';

export const ProductImageWithBadges = ({
  attributes,
  productName,
  productSlug,
  imageUrl,
  isPublished,
  isNotClickable,
  isPromoted,
  isCounterFolded,
}: {
  attributes: Product['attributes'];
  productName: string;
  productSlug: string;
  imageUrl: string;
  isPublished: boolean;
  isNotClickable?: boolean;
  isPromoted: boolean;
  isCounterFolded: boolean;
}) => {
  const { onClick } = useProductTileContext();

  return (
    <div className={cn('relative')}>
      <div className="relative mt-5 px-4 pb-4 pt-3">
        <div className="absolute z-10">
          <ProductBadgesList
            tags={attributes.tags}
            direction="column"
            maxNumberOfBadgesOnDesktop={3}
            maxNumberOfBadgesOnMobile={3}
            showOnlyIcon
            unfoldOnHover={isCounterFolded}
            color={isPromoted ? 'promoted' : 'primary'}
          />
        </div>

        <div className="relative m-auto size-[108px] p-2">
          <ProductPageLink
            productSlug={productSlug}
            isDisabled={!isPublished || isNotClickable}
            onClick={onClick}
          >
            <ProductImage alt={productName} url={imageUrl} />
          </ProductPageLink>
        </div>
      </div>
    </div>
  );
};

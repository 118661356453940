import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { OldPrice } from '@/core/ui/components/OldPrice/OldPrice';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';

export const ProductTilePrice = ({
  price,
  isPromoted,
}: {
  price: Product['price'];
  isPromoted: boolean;
}) => {
  const discount = price.discounted;

  return (
    <div className={cn('flex', 'gap-x-2', 'items-center')}>
      <span
        className={cn(
          discount
            ? [isPromoted ? 'text-text-top' : 'text-text-primary']
            : 'text-primary-900',
          'text-base',
          'leading-5',
          'md:leading-6'
        )}
      >
        <FormattedPrice
          centAmount={
            discount ? discount.value.centAmount : price?.value.centAmount
          }
          currencyCode={
            discount ? discount.value.currencyCode : price?.value.currencyCode
          }
        />
      </span>

      {discount && (
        <span className={cn('text-xs')}>
          <OldPrice
            color={cn(isPromoted && 'text-text-subtle')}
            centAmount={price.value.centAmount}
            currencyCode={price.value.currencyCode}
          />
        </span>
      )}
    </div>
  );
};

import { createContext, ReactNode } from 'react';

import { Product } from '../queries/types';

export const ProductTileContext = createContext<
  ProductTileContextType | undefined
>(undefined);

type ProductTileContextType = {
  product: Product;
  additionalBottomContent?: ReactNode;
  isNotClickable?: boolean;
  showPromoted?: boolean;
  onClick?: () => Promise<void>;
};

import { forwardRef, memo, ReactNode, useCallback, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { ProductTileContext } from '@/modules/product/context/ProductTileContext';
import { Product } from '@/modules/product/queries/types';

import { ProductTileBorderedSkeleton } from './components/ProductTileBorderedSkeleton';
import { ProductTileInner } from './components/ProductTileInner';

export type ProductTileProps = {
  product: Product;
  additionalBottomContent?: ReactNode;
  isNotClickable?: boolean;
  showPromoted?: boolean;
  onClick?: () => Promise<void>;
};

const _ProductTile = forwardRef<HTMLLIElement, ProductTileProps>(
  (
    { product, additionalBottomContent, isNotClickable, showPromoted, onClick },
    ref
  ) => {
    const [setTileRef, isTileInViewport] = useInView({
      triggerOnce: true,
    });

    const customRef = useCallback(
      (node: HTMLLIElement | null) => {
        setTileRef(node);
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      },
      [ref, setTileRef]
    );

    const productTileContextValue = useMemo(
      () => ({
        product,
        additionalBottomContent,
        isNotClickable,
        showPromoted,
        onClick,
      }),
      [product, additionalBottomContent, isNotClickable, showPromoted, onClick]
    );

    return (
      <li ref={customRef} data-testid="wrapper" data-product-id={product.id}>
        {isTileInViewport ? (
          <ProductTileContext.Provider value={productTileContextValue}>
            <ProductTileInner />
          </ProductTileContext.Provider>
        ) : (
          <ProductTileBorderedSkeleton />
        )}
      </li>
    );
  }
);

export const ProductTile = memo(_ProductTile);

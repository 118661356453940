import { memo, useState } from 'react';

import { PRODUCT_TILE_HEIGHT_CLASS } from '@/core/constants/productTile';
import { FormattedNetContain } from '@/core/ui/components/FormattedNetContain/FormattedNetContain';
import { cn } from '@/core/ui/utils';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { useProductTileContext } from '@/modules/product/hooks/useProductTileContext';

import { Badge } from '../../Badge/Badge';
import { BundleBadge } from '../../BundleBadge/BundleBadge';

import { AlcoholProductTileOverlay } from './AlcoholProductTileOverlay';
import { FoldableProductCounter } from './FoldableProductCounter';
import { Omnibus } from './Omnibus';
import { OutOfStockBadge } from './OutOfStockBadge';
import { ProductImageWithBadges } from './ProductImageWithBadges';
import { ProductName } from './ProductName';
import { ProductTilePrice } from './ProductTilePrice';
import { ProductTilePromotedBadge } from './ProductTilePromotedBadge';

export const ProductTileInner = memo(() => {
  const authRole = useSession('authRole');
  const {
    product: {
      imagesUrls: [imageUrl = ''],
      name: productName,
      slug: productSlug,
      price,
      attributes,
      availableQuantity,
      isPublished,
      sku,
      productType,
      ...product
    },
    additionalBottomContent,
    isNotClickable,
    showPromoted,
  } = useProductTileContext();

  const isOnStock = availableQuantity > 0 && isPublished;
  const shouldShowCover =
    authRole !== AUTH_ROLE.AUTHENTICATED && attributes?.contains_alcohol;
  const hasMaxQuantity = Boolean(price?.multibuy?.maxQuantity);
  const isPromoted = Boolean(
    showPromoted && 'isPromotedProduct' in product && product?.isPromotedProduct
  );
  const isBundle = productType === 'complex-bundle';

  const [isCounterFolded, setIsCounterFolded] = useState(true);

  return (
    <div
      data-testid={`product-tile-${productSlug}`}
      className={cn(
        'relative',
        'rounded-xl',
        !shouldShowCover && ['shadow-border-inset', 'shadow-border-subtle'],
        !isOnStock && 'grayscale',
        PRODUCT_TILE_HEIGHT_CLASS,
        isPromoted && 'bg-decorative-surface-top shadow-decorative-surface-top'
      )}
    >
      {isPromoted && <ProductTilePromotedBadge />}
      {shouldShowCover && <AlcoholProductTileOverlay />}
      <div className={cn('absolute', 'top-3.5', 'right-3', 'z-10')}>
        <FoldableProductCounter
          sku={sku}
          availableQuantity={availableQuantity}
          isPublished={isPublished}
          size="md"
          iconSize="md"
          isFolded={isCounterFolded}
          setIsCounterFolded={setIsCounterFolded}
        />
      </div>
      <div
        className={cn(
          shouldShowCover && 'blur',
          'h-full',
          'relative',
          'flex',
          'flex-col'
        )}
      >
        <ProductImageWithBadges
          attributes={attributes}
          productName={productName}
          productSlug={productSlug}
          imageUrl={imageUrl}
          isPublished={isPublished}
          isNotClickable={isNotClickable}
          isPromoted={isPromoted}
          isCounterFolded={isCounterFolded}
        />
        <ProductName
          productName={productName}
          productSlug={productSlug}
          isPublished={isPublished}
          isNotClickable={isNotClickable}
        />
        <p
          className={cn(
            'text-xs',
            'truncate',
            'mx-3',
            'xsm:mx-4',
            'text-text-subtle'
          )}
        >
          <FormattedNetContain
            netContain={attributes.net_contain}
            containUnit={attributes.contain_unit}
          />
        </p>
        <div
          className={cn(
            'mx-3',
            'xsm:mx-4',
            'grow',
            'mb-5',
            'flex',
            'flex-col',
            'justify-end',
            'gap-2'
          )}
        >
          <div className={cn('flex', !isOnStock && cn('flex-col', 'mt-auto'))}>
            {isOnStock ? (
              <ProductTilePrice price={price} isPromoted={isPromoted} />
            ) : (
              <div className={cn('mt-1')}>
                <OutOfStockBadge />
              </div>
            )}
          </div>
          {isOnStock && (isBundle || price?.discounted) && (
            <div className="flex gap-150">
              {price?.discounted ? (
                <Omnibus price={price} isPromoted={isPromoted}>
                  {isBundle && <BundleBadge />}
                </Omnibus>
              ) : (
                isBundle && <BundleBadge />
              )}
            </div>
          )}
          {isOnStock && price?.multibuy?.description && !price?.discounted && (
            <Badge
              variant={hasMaxQuantity ? 'multiby-max-quantity' : 'multibuy'}
            >
              {price.multibuy.description}
            </Badge>
          )}

          {additionalBottomContent}
        </div>
      </div>
    </div>
  );
});

import { useContext } from 'react';

import { ProductTileContext } from '../context/ProductTileContext';

export const useProductTileContext = () => {
  const context = useContext(ProductTileContext);

  if (!context) {
    throw new Error(
      'useProductTileContext must be used within a ProductTileContextProvider'
    );
  }

  return context;
};
